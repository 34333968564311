.projects {
  min-height: 100vh;

  h2 {
    font-family: $font-base;
    font-weight: bold;
    font-size: 2rem;
    color: rgb(255, 255, 255);
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 2rem 0 1rem 2rem;
    width: 50vw;
    
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      right: 0;
      bottom: -3px;
      background-color: rgb(255, 255, 255);
      z-index: 1;
    }
  }

  a {
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    transition: all 200ms ease;
    &:hover {
      background-color: $bg-color2;
      color: black;
    }
  }

  .swipe {
    text-align: center;
  }
  .swipe-nav {
    min-height: 10vh;
    background-color: $bg-color2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: $bg-color1;

    h1 {
      color: $bg-color1;
      font-family: $font-base;
      width: 50vw;
      font-weight: 800;
      font-size: 1.5rem;
      background-color: $bg-color2;
      padding: 0.5rem;
      margin-bottom: 2rem;
      border-radius: 4px;
    }

    img {
      max-width: 80%;
      width: 50vw;
      margin-bottom: 2rem;
      border-radius: 4px;
    }

    .project-src {
      display: flex;
      justify-content: center;
      color: white;
      font-family: $font-base;
      p {
        padding: 1rem;
      }
    }
  }
  
  button {
    width: 100px;
    height: 100%;
    background-color: rgb(216, 216, 216);
    cursor: pointer;
    color: white;
    padding: 0 5rem 0 5rem;
    border: 0;
    &:hover i {
      transform: scale(1.1);
    }

    i {
      transition: transform 50ms linear;
      font-size: 2rem;
      color: rgb(6, 155, 155);
    }
  }
}

@media (max-width: 500px) {
  .projects {
    .p1 {
      h1 {
        width: 80vw;
        font-size: 1rem;
      }
      img {
        width: 80vw;
      }
      .project-src {
        width: 80%;
      }
    }
  }
}
