.contact {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  min-height: 100vh;

  .contact-title {
    //padding-top: 3rem;
  }

  h2 {
    font-family: $font-base;
    font-weight: bold;
    font-size: 2rem;
    color: rgb(255, 255, 255);
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 2rem 0 2rem 3rem;
    width: 50vw;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      right: 0;
      bottom: -3px;
      background-color: rgb(255, 255, 255);
    }
  }

  p {
    font-family: $font-base;
    font-weight: bold;
    color: rgb(224, 224, 224);
    padding: 1rem;
  }

  i {
    font-size: 2rem;
    color: rgb(6, 255, 255);
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    width: 50vw;
    height: 10vh;
    transition: all 200ms ease;
    padding-left: 3rem;
    &:hover {
      background-color: rgba(109, 108, 108, 0.2);
      cursor: pointer;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  a {
    text-decoration: none;
  }
}

@media (max-width: 700px) {
  body {
    .contact {
      align-items: flex-start;
      margin: 0;

      .contact-title {
        padding-top: 1rem;
      }

      h2 {
        padding-left: 10px;
        width: 90vw;
      }
      span {
        width: 100vw;
        justify-content: flex-start;
        height: 10%;
        padding: 1rem;
      }
      p {
        font-size: 1rem;
      }
      .row {
        flex-direction: row;
      }
    }
  }
}
