.title {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  background-color: rgb(216, 216, 216);
  position: relative;

  .menu {
    a {
      cursor: pointer;
      padding: 2rem 0 1rem 0;
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-family: $font-base;
      font-weight: bold;
      width: 10%;
      transition: all 200ms ease-out;
      position: relative;
      color: rgb(88, 86, 86);
      animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      &:nth-child(1) {
        animation-delay: 0.2s;
      }
      &:nth-child(2) {
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
      &::after {
        content: "";
        display: none;
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        right: 0;
        bottom: -3px;
        background-color: rgb(6, 155, 155);
      }
      &:hover {
        border-bottom: 1px solid rgba(6, 155, 155, 0.5);
        width: 20%;
        color: rgb(0, 0, 0);
      }
    }
    a:hover:after {
      display: block;
    }
  }

  span {
    background-color: rgb(6, 155, 155);
    border-radius: 10px;
    color: white;
    padding: 0 2rem 0 2rem;
  }

  .title-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 3rem;

    h1 {
      padding-top: 3rem;
      font-size: 5rem;
      text-transform: uppercase;
    }
    h2 {
      font-family: $font-base;
      font-weight: bold;
      font-size: 2rem;
      color: rgb(6, 155, 155);
      position: relative;
      border-bottom: 1px solid rgba(6, 155, 155, 0.5);
      padding: 1rem 0 1rem 0;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        right: 0;
        bottom: -5px;
        background-color: rgb(6, 155, 155);
      }
    }
  }

  .social-links {
    position: absolute;
    display: flex;
    bottom: 2%;
    i {
      font-size: 2rem;
      padding: 2rem 2rem 2rem 0;
    }
    a {
      color: black;
    }
  }
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 700px) {
  body {
    .title {
      max-height: 100vh;
      align-items: flex-start;
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1rem;
      }
      .title-left {
        margin: 0;
        padding: 1rem;
        width: 100%;
        h1 {
          font-size: 2rem;
          padding-top: 1rem;
        }
        h2 {
          font-size: 1rem;
        }
      }
    }
  }
}

@media (max-width: 700px) and (orientation: landscape) {
  .social-links {
    right: 2%;
  }
}
